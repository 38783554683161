@import '../../styles/customMediaQueries.css';

.sectionHero {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;

  /* Style */
  background-image: url('./img/hero-img.jpg');
  background-position: bottom;
  background-size: cover;

  @media (--viepwortMedium) {
    padding: 72px 24px;
  }
}

.heroContent {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Font */
  text-align: center;

  /* Size */
  padding: 32px 48px;

  /* Style */
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
}

.heroTitle {
  /* Font */
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 12px;
}

.heroText {
  margin-top: 0;
  margin-bottom: 12px;

  max-width: 550px;
}

.heroBreadcrumbs {
  display: flex;
  align-items: center;
}

.heroBreadcrumb {
  display: flex;
  align-items: center;

  & .categoryBg {
    margin-right: 6px;
  }
}

.mainBreadcrumb {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.heroBreadcrumbName,
.heroBreadcrumbLink {
  composes: marketplaceDefaultFontStyles from global;
  font-weight: var(--fontWeightMedium);
  line-height: 22px;

  margin-top: 0;
  margin-bottom: 0;
}

.heroBreadcrumbSeparator {
  display: none;

  @media (--viewportMedium) {
    display: block;

    /* Font */
    composes: marketplaceDefaultFontStyles from global;
    font-weight: var(--fontWeightMedium);
    line-height: 22px;

    margin: 0 6px;
  }
}

.pageWrapper {
  composes: marketplacePageWidth from global;
  margin: 48px auto 124px;

  @media (--viewportMedium) {
    /* Layout */
    display: grid;
    grid-template-columns: auto 300px;
    grid-gap: 72px;

    margin: 48px auto;
  }
}

.posts {
}

.post {
  display: block;

  /* Font */
  text-decoration: none !important;

  /* Position */
  position: relative;
  color: var(--matterColor);

  /* Style */
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);
  padding: 24px;

  /* Animation */
  transition: var(--transitionStyleButton);
  cursor: pointer;

  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: var(--matterColorBright);
  }

  @media (--viewportMedium) {
    /* Layout */
    display: grid;
    grid-template-columns: auto 25%;
    grid-gap: 48px;
    padding: 16px;
  }
}

.postLeftContent {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.postRightContent {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
}

.postAvatar {
  flex-shrink: 0;

  width: 32px;
  height: 32px;

  @media (--viewportMedium) {
    width: 48px;
    height: 48px;
  }
}

.postContent {
  @media (--viewportMedium) {
    padding-top: 6px;
  }
}

.postTitle {
  /* Font */
  font-weight: var(--fontWeightMedium);

  margin-top: 0;
  margin-bottom: 0;
}

.postTime {
  margin-top: 6px;
  margin-bottom: 0;

  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  margin-top: 6px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    align-items: center;
  }
}

.postText {
  /* Font */
  composes: marketplaceSmallFontStyles from global;
  line-height: 24px;

  margin-top: 12px;
  margin-bottom: 0;

  /* Limit text to max 2 lines */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.highlightedText {
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
}

.timeIcon {
  height: 12px;
  width: 12px;

  margin-right: 6px;
}

.postSmallAvatars {
  display: flex;
  align-items: center;

  margin-bottom: 12px;

  /* Initials */
  & span {
    font-size: 12px;
  }
}

.postSmallAvatar {
  /* Size */
  height: 32px;
  width: 32px;

  margin-right: -6px;
  &:last-child {
    margin-right: 0;
  }
}

.postComments {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;
  color: #7c7c7c;

  margin-top: 0;
  margin-bottom: 0;
}

.postLabel {
  /* Position */
  position: absolute;
  right: 16px;
  top: -12px;

  /* Style */
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);
  padding: 4px 6px;
}

.postLabelText {
  /* Layout */
  display: flex;
  align-items: center;

  /* Font */
  composes: marketplaceTinyFontStyles from global;

  margin-top: 0;
  margin-bottom: 0;
}

.postLabelColor {
  height: 5px;
  width: 5px;

  margin-right: 6px;

  /* Style */
  border-radius: 50%;
}

.sidebar {
  /* Position */
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;

  /* Style */
  background: var(--matterColorLight);

  padding: 24px;
  width: 100%;

  @media (--viewportMedium) {
    position: unset;
    background-color: unset;
    box-shadow: unset;
    padding: unset;
    width: auto;
    z-index: unset;
  }
}

.sidebarButton {
  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Style */
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}

.sidebarCategories {
  display: none;

  @media (--viewportMedium) {
    display: block;

    margin-top: 24px;
    margin-bottom: 0;
  }
}

.mobileSidebarCategories {
  /* Layout */
  display: flex;
  align-items: center;
  overflow-y: auto;

  margin: 0 -24px 48px;

  & .sidebarCategory {
    /* Font */
    white-space: nowrap;

    /* Style */
    padding: 8px 12px;
    background-color: var(--matterColorBright);
    border-radius: var(--borderRadiusMedium);

    margin-bottom: 0;
    margin-right: 12px;

    &:first-child {
      margin-left: 24px;
    }
    &:last-child {
      margin-right: 24px;
    }
  }

  @media (--viewportMedium) {
    display: none;
  }
}

.sidebarCategory {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);

  /* Layout */
  display: flex;
  align-items: center;

  margin-top: 0;
  margin-bottom: 16px;

  /* Animation */
  cursor: pointer;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: var(--marketplaceColor);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.sidebarCategoryActive {
  color: var(--marketplaceColor);
}

.categoryBg {
  /* Style */
  height: 12px;
  width: 12px;
  border-radius: 50%;
  flex-shrink: 0;

  margin-right: 12px;
}

.pagination {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 48px;
  }
}

.noPosts,
.searchError {
  padding: 24px;

  /* Style */
  background: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);
}

.noPostsTitle,
.searchErrorTitle {
  margin: 0 0 12px;
}

.searchErrorTitle {
  color: var(--failColor);
}

.noPostsText,
.searchErrorText {
  composes: marketplaceSmallFontStyles from global;

  margin-top: 0;
  margin-bottom: 0;
}

.labelWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
}

.checkmark {
  width: 12px;
  height: 12px;

  @media (--viewportMedium) {
    width: 16px;
    height: 16px;
  }
}

.returnLink {
  /* Layout */
  display: flex;
  align-items: center;
  width: fit-content;

  /* Font */
  composes: marketplaceSmallFontStyles from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 12px;

  /* Animation */
  transition: none;
}

.returnLinkIcon {
  height: 10px;
  width: 10px;
  margin-right: 6px;
}

.commentIcon {
  height: 14px;
  width: 14px;
  margin-right: 6px;

  & path {
    stroke: #7c7c7c !important;
  }
}
